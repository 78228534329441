import { Injectable, Injector } from '@angular/core';
import { TimelineElementAbstractFactory, MedicalTimelineElement, SurveyTimelineElement, LostTransmissionTimelineElement, DiagnosticProcedureTimelineElement, IotMeasurementTimelineElement } from '@madrija/medical-ui/common';
import { Router } from '@angular/router';
import { FaDebutTimelineElement, FollowUpTimelineElement, OperationTimelineElement } from '@madrija/crm-ui/common';
import { ProcedureTimelineElement as EpProcedureTimelineElement } from '@madrija/electro-ui/common';
import { ProcedureTimelineElement as SihProcedureTimelineElement, FollowUpTimelineElement as SihFollowUpTimelineElement } from '@madrija/sih-ui/common';
import { EcgTimelineElement as EcgTestTimelineElement } from '@madrija/ecg-ui/common';
import { EcoCardioTimelineElement, TacTimelineElement } from '@madrija/cardio-image-ui/common';
import { CvisOrderHistoryDTO } from '../models/cvisOrderHistoryDTO.model';

@Injectable()
export class CvisTimelineElementFactory extends TimelineElementAbstractFactory {
  public getInstance(type: string, data: CvisOrderHistoryDTO, tooltip: { data: CvisOrderHistoryDTO, injector: Injector }, selected: number): MedicalTimelineElement {
    let value: MedicalTimelineElement = new MedicalTimelineElement(0, new Date(), 'timeline-element', null);
    const router: Router = tooltip.injector.get(Router);
    const actualURL = window.location.pathname;
    switch (type) {
      case 'CrmOperation':
        value = new OperationTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : (data.importType === 'REMOTE') ? 'remote' : 'timeline-element', data.orderId === selected, () => {
            const actualCrmUrl = window.location.pathname;
            const route = actualCrmUrl.replace(/\/(\w+\/){3}\d+\//gm, `views/crm/operation/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/crm.svg', { icon: 'f7b9', iconColor: '#ffc83f' });

        break;
      case 'CrmFollowUp':
        value = new FollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : (data.importType === 'REMOTE') ? 'remote' : 'timeline-element', data.orderId === selected, () => {
            const actualCrmUrl = window.location.pathname;
            const route = actualCrmUrl.replace(/\/(\w+\/){3}\d+\//gm, `views/crm/follow/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/crm.svg', { icon: 'f0f1', iconColor: '#16c98d' });
        break;
      case 'EpProcedure':
        value = new EpProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, () => {
            const route = `views/ep/procedure/${data.orderId}/procedure`;
            router.navigate([route]);
          }, 'assets/images/modules/ep.svg', { icon: 'f7b9', iconColor: '#ffc83f' });
        break;
      case 'SihProcedure':
        value = new SihProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, () => {
            const route = `views/sih/procedure/${data.orderId}/dynamicRecord`;
            router.navigate([route]);
          }, 'assets/images/modules/sih.svg', { icon: 'f7b9', iconColor: '#ffc83f' });
        break;
      case 'SihFollowUp':
        value = new SihFollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, () => {
            const route = `views/sih/followup/${data.orderId}/generalData`;
            router.navigate([route]);
          }, 'assets/images/modules/sih.svg', { icon: 'f0f1', iconColor: '#16c98d' });
        break;
      case 'CimEcoCardio':
        value = new EcoCardioTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, () => {
            const route = actualURL.replace(/\/(\w+\/){3}\d+\//gm, `views/cim/ecocardio/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cim.svg', { imageSrc: 'assets/images/order-types/eco.svg', iconColor: '#6ba5d7' });
        break;
      case 'CimTAC':
        value = new TacTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/(\w+\/){3}\d+\//gm, `views/cim/tac/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cim.svg', { imageSrc: 'assets/images/order-types/tac.svg', iconColor: '#6ba5d7' });
        break;
      case 'EcgTest':
        value = new EcgTestTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, () => {
            const actualURL = window.location.pathname;
            const route = actualURL.replace(/\/(\w+\/){3}\d+\//gm, `views/ecg/test/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/ecg.svg', { icon: 'f0f1', iconColor: '#16c98d' });
        break;
      case 'FA_DEBUT':
        value = new FaDebutTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'SURVEY':
        value = new SurveyTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'LOST_TRANSMISSION':
        value = new LostTransmissionTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'DIAGNOSTIC_PROCEDURE':
        value = new DiagnosticProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'IOT_MEASUREMENT':
        value = new IotMeasurementTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;

      default:
        value.type = data.dtype ?? '';
        break;
    }
    return value;
  }
}
