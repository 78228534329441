import { Injectable } from '@angular/core';
import { PriorityType, BradycardiaElectrodeType, ShockCountType, ImportType, FollowupType, DeviceCameralType, DeviceType, OperationType, TachycardiaType } from '@madrija/crm-ui/common';
import { ClinicalEventType, FlagColors, OrderStatus, Scope, ScopeType, Sex } from '@madrija/medical-ui/common';
import { EnumFactory } from '@madrija/base-ui/dynamic-form';
import { SegmentalContractility, CardiacAppendageType } from '@madrija/cardio-image-ui/common';
import { PTCAType } from '@madrija/sih-ui/common';
import { Veins, Substrates, NYHAClass, Grade, BrugadaType, TurnDirection } from '@madrija/electro-ui/common'
import { TaviType } from '@madrija/cardiovascular-ui/common';

@Injectable()
export class CvisEnumFactoryService extends EnumFactory {

  getMap() {
    return new Map()
      //CRM
      .set('Sex', Sex).set('ClinicalEventType', ClinicalEventType).set('Scope', Scope).set('ScopeType', ScopeType).set('OrderStatus', OrderStatus).set('FlagColors', FlagColors)
      .set('PriorityType', PriorityType).set('BradycardiaElectrodeType', BradycardiaElectrodeType).set('ImportType', ImportType).set('FollowupType', FollowupType)
      .set('DeviceType', DeviceType).set('DeviceCameralType', DeviceCameralType).set('OperationType', OperationType).set('TachycardiaType', TachycardiaType).set('ShockCountType', ShockCountType)
      //CIM
      .set('SegmentalContractility', SegmentalContractility)
      .set('CardiacAppendageType', CardiacAppendageType)
      .set('TaviType', TaviType)
      //SIH
      .set('PTCAType', PTCAType)
      //EP
      .set('Veins', Veins).set('Substrates', Substrates).set('NYHAClass', NYHAClass).set('Grade', Grade).set('BrugadaType', BrugadaType).set('TurnDirection', TurnDirection)
  }
}
