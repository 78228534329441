<mab-delete-dialog #sureSendSMS [title]="'TIMELINE.SendSMS' | translate"
  [delete_msg]="'REPORT.noFavouritePhone' | translate" [cancel]="'COMMON.Cancel' | translate"
  [continue]="'COMMON.Continue' | translate" (continueClick)="callFunction.emit({fn:'sendSMS'}); sureSendSMS.hide();"
  (cancelClick)="sureSendSMS.hide(); callFunction.emit({fn:'hideLoadingNotDestoyReport'})"></mab-delete-dialog>
<ng-template #toastTemplate>
  <button class="btn btn-primary float-end mt-2 mb-2" (click)="toastClick($event)">
    <i aria-hidden="true" class="fa fa-info-circle"></i>
    {{'REPORT.MoreInfo' | translate}}
  </button>
</ng-template>
<ng-template #chpaceMsgDlg let-modal>
  <div class="modal-header">
    <p class="heading lead mb-0 w-50" [translate]="'REPORT.NotSentReport'"></p>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    {{chpaceMsg}}
  </div>
</ng-template>
<ng-template #notEpisodeID let-modal>
  <div class="modal-header">
    <p class="heading lead mb-0 w-75">{{'TIMELINE.SelectEpisode' | translate}}</p>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close();">
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>
  </div>
  <div class="modal-footer">
    <button data-bs-dismiss="modal" (click)="modal.close();" class="btn btn-secondary">
      <span [translate]="'COMMON.Cancel'"></span>
    </button>
    <button data-bs-dismiss="modal" (click)="selectEpisode()" class="btn btn-primary">
      <span [translate]="'COMMON.Save'"></span>
    </button>
  </div>
</ng-template>
<ng-template #tableTemplate>
	<mab-basetable [tableId]="'episodes_table'" #table [totalRecords]="episodes.length" [paginator]="true" [loading]="false"
		[globalfilter]="false" [columnfilter]="true" [filtertype]="true" [class]="''" [items]="episodes"
		[columns]="columns" [not_found]="'COMMON.EmptyList' | translate"  [radiorow]="true" [selectionMode]="'single'">
	</mab-basetable>
</ng-template>
<ng-template #printCardTemplate let-modal>
	<div class="modal-header">
		<p class="heading lead mb-0 w-75">{{'TIMELINE.PrintCard' | translate}}</p>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close(); callFunction.emit({fn:'hideLoadingNotDestoyReport'})">
		</button>
	</div>
	<div class="modal-body">
		<p class="float-start">
			{{'COMMON.YouSure' | translate: ({'action': ('TIMELINE.PrintCard' | translate)})}}
		</p>
	</div>
	<div class="modal-footer">
		<button data-bs-dismiss="modal" (click)="modal.close(); callFunction.emit({fn:'hideLoadingNotDestoyReport'})" class="btn btn-secondary">
			<span [translate]="'COMMON.Cancel'"></span>
		</button>
		<button data-bs-dismiss="modal" (click)="printCard()" class="btn btn-primary">
			<span [translate]="'TIMELINE.Print'"></span>
		</button>
	</div>
</ng-template>