import { MasterReport, MasterReportAbstractFactory } from "@madrija/base-ui/common";
import { MasterReportDTOHelper } from "@madrija/crm-ui/common";
import { MasterReportDTOHelper as SihMasterReportDTOHelper, ProcedureHelper as SihProcedureHelper } from "@madrija/sih-ui/common";
import { PatientHistoryHelper } from "@madrija/medical-ui/common";
import { ProcedureHelper as EpProcedureHelper } from "@madrija/electro-ui/common";
import { EcoCardioHelper } from "@madrija/cardio-image-ui/common";

export class MasterReportFactory extends MasterReportAbstractFactory {

    static categories = ['CrmOperation', 'CrmFollowUp', 'MedPatient', 'CimEcoCardio', 'CimTAC', 'SihProcedure', 'EpProcedure'];

    getAvailableCategories(): string[] {
        return MasterReportFactory.categories;
    }

    override getInstance(category: string, object: any): MasterReport {
        switch (category) {
            case MasterReportFactory.categories[0]: case MasterReportFactory.categories[1]:
                const op = MasterReportDTOHelper.buildFromJSON(object.context?.master_report ?? object) ?? EmptyMasterReport;
                return op;
            case MasterReportFactory.categories[2]:
                return PatientHistoryHelper.buildFromJSON(object.context?.master_report ?? object);
            case MasterReportFactory.categories[3]:
                return EcoCardioHelper.buildFromJSON(object.context?.master_report.ecoCardio ?? object);
            // case MasterReportFactory.categories[4]:
            //     return TacHelper.buildFromJSON(object.context?.master_report ?? object);
            case MasterReportFactory.categories[5]:
                const sih = SihMasterReportDTOHelper.buildFromJSON(object.context?.master_report ?? object) ?? EmptyMasterReport;
                return sih;
            case MasterReportFactory.categories[6]:
                return EpProcedureHelper.buildFromJSON(object);
            default:
                return new EmptyMasterReport();
        }
    }
}

export class EmptyMasterReport implements MasterReport { }
