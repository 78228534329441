<mmui-appointments #appointments [createEventFn]="createEvent" [createTooltipFn]="createTooltip" [eventRenderFn]="eventRender"
[translateOrderType]="translateOrderType" [dtoMapper]="dtoMapper"
[whereToNavigate]="whereToNavigate" [getOrderIconByDtype] = getOrderIconByDtype [viewsModuleBasePath]="'/views/medical/'">
    <mab-delete-dialog id="saveDialog" #saveDlg [title]="'COMMON.Save' | translate"
      [delete_msg]="'COMMON.YouSure' | translate:{'action':'Order.changeOrderType' | translate}"
      value="{{'Order.noteChangeOrderType' | translate}}"
      [cancel]="'COMMON.Cancel' | translate"
      [continue]="'COMMON.Continue' | translate"
      (continueClick)="modalSaveButtonClick(); saveDlg.hide();"
      (cancelClick)="cancelSave()"></mab-delete-dialog>
</mmui-appointments>