import { Injectable } from "@angular/core";
import { OrderHelper, OrderAbstractFactory } from "@madrija/medical-ui/common";
import { ProcedureHelper as EpProcedureHelper } from "@madrija/electro-ui/common";
import { ProcedureHelper as SihProcedureHelper } from "@madrija/sih-ui/common";
import { FollowUpHelper, OperationHelper } from "@madrija/crm-ui/common";
import { EcoCardioHelper, TacHelper } from '@madrija/cardio-image-ui/common'

@Injectable()
export class CvisOrderAbstractFactory extends OrderAbstractFactory {
    public getInstance(dtype: string): any {
        let value = null;
        switch (dtype) {
            case 'CrmOperation':
                value = OperationHelper;
                break;
            case 'CrmFollowUp':
                value = FollowUpHelper;
                break;
            case 'EpProcedure':
                value = EpProcedureHelper;
                break;
            case 'SihProcedure':
                value = SihProcedureHelper;
                break;
            case 'CimEcoCardio':
                value = EcoCardioHelper
                break;
            case 'CimTAC':
                value = TacHelper
                break;
            default:
                value = OrderHelper;
                break;
        }
        return value;
    }
}
