import { Injectable, Injector } from '@angular/core';
import { TimelineHelper, TimelineElement } from '@madrija/angular-base/chart';
import { TimelineElementAbstractFactory } from '@madrija/medical-ui/common';
import { CvisOrderHistoryDTOHelper } from './cvisOrderHistoryDTO.helper';

@Injectable()
export class CvisTimelineHelper extends TimelineHelper {
  private factory: TimelineElementAbstractFactory;
  constructor(private injector: Injector) {
    super();
    this.factory = this.injector.get(TimelineElementAbstractFactory);
  }

  toEvent(data: any, selected: number): TimelineElement | null {
    const dto = CvisOrderHistoryDTOHelper.buildFromJSON(data);
    const tooltip = { data: dto, injector: this.injector };
    return this.factory.getInstance(dto.dtype!, dto, tooltip, selected);
  }
}
