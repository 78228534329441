import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentFormComponent } from '@madrija/medical-ui/shared-components';
import { Appointment, AppointmentHelper, Order, OrderStatus } from '@madrija/medical-ui/common';
import { TranslateRootPipe } from '@madrija/angular-base/common';
import { NgOption } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CrmAppointment, OperationType } from '@madrija/crm-ui/common';
import { DeviceTypeComponent } from '@madrija/crm-ui/shared-components';
import { ToastService } from '@madrija/angular-base/toast';
import { CvisAppointmentDTO } from 'src/app/models/cvis-appointment.dto.model';
import { CvisAppointment } from 'src/app/models/cvis-appointment.model';

@Component({
  selector: 'app-appointment-order-template',
  templateUrl: 'appointment-order-template.component.html',
  styleUrls: ['./appointment-order-template.scss'],
  providers: [TranslateRootPipe]
})
export class AppointmentFormOrderTemplateComponent {
  @ViewChild('deviceTypeComp', { static: false }) deviceTypeComp: DeviceTypeComponent | null = null;
  @ViewChild('orderTypeTemplate', { static: true }) orderTypeTemplate: TemplateRef<any> | null = null;
  _parent: AppointmentFormComponent | null = null;
  operationTypes: NgOption[] = [];
  activeId: string = '';
  appointment: CrmAppointment | null = null;
  public orderTypeForm: FormGroup;
  public orderTypes = ['FollowUp', 'Operation', 'Procedure', 'TAC', 'EcoCardio', 'Test'];
  public cvisModules = ['Crm', 'Ep', 'Cim', 'Sih', 'Ecg', 'Uc', 'Rhc', 'Insufi'];
  public showFooter = false;

  constructor(@Inject('LOCALE_SUBJECT') locale: Subject<any>, private translationPipe: TranslateRootPipe,
    private translationService: TranslateService, private toastr: ToastService, private formBuilder: FormBuilder,) {
    this.translationService.onLangChange.subscribe(() => {
      this.getOperationTypes();
    });
    locale.subscribe(() => {
      this.getOperationTypes();
    });
    this.orderTypeForm = this.formBuilder.group({
      module: [null, null]
    });
  }

  ngOnInit(): void {
    if (this.form) this.form.setControl('orderType', new FormControl(null, Validators.required));
    this.addControl('origin');
    this.addControl('priority');
    this.addControl('otherComments');
    this.addControl('orderStatus');
    // this.activeId = this.setFirstLicenseModule();
  }

  getSelectedModuleByOrderType(value: string) {
    let module = value === 'CrmOperation' || value === 'CrmFollowUp' ? 'crm'
      : value === 'EpProcedure' ? 'ep'
        : value === 'SihFollowUp' || value === 'SihProcedure' ? 'sih'
          : value === 'CimEcoCardio' || value === 'CimTAC' ? 'cim'
            : value === 'EcgTest' ? 'ecg' : 'crm';
    this.orderTypeForm.controls['module'].setValue(module);
  }

  operationClicked() {
    if (!this.operationType) {
      this.addControl('operationType');
    }
  }

  addControl(name: string) {
    if (this.form) {
      this.form.addControl(name, new FormControl(null));
    }
  }

  hasModule(module: string) {
    let property = localStorage.getItem('CVIS_LICENSE_MODULES');
    property = property?.replace(' ', '') ?? '';
    const modules = property.split(',');
    return modules.includes(module);
  }


  private getOperationTypes() {
    this.operationTypes = [];
    for (const iterator of Object.keys(OperationType)) {
      const label = this.translationPipe.transform('OPERATION.OperationType.' + iterator);
      this.operationTypes.push({ label: label, value: iterator });
    }
  }

  public hasOrderCreated() {
    const orders = this.form.controls['order'].value;
    return orders !== null && orders.some((order: Order) => order.dtype);;
  }

  private detectTab(appointment: Appointment): string {
    const orders = appointment.order;
    return orders && orders[0] && orders[0].dtype ? orders[0].dtype.split(/(?=[A-Z])/)[0].toLowerCase() : this.activeId;
  }


  public moduleHasType(type: string) {
    const module = this.orderTypeForm.controls['module'].value;
    return CvisAppointment.moduleHasType(module, type);
  }

  public show(view: string) {
    if (view === 'module' && !this.orderTypeForm.controls['module'].value) {
      this.showFooter = false;
      return true;
    }
    else if (view === 'type' && this.orderTypeForm.controls['module'].value) {
      this.showFooter = true;
      return true
    } else {
      this.showFooter = false;
      return false;
    }
  }

  public backToSelectModule() {
    this.orderTypeForm.get('module')?.reset();
    this.form.get('orderType')?.reset();
  }

  public capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public selectType(type: string) {
    return this.form.controls['orderType'].value === type;
  }

  get form() {
    return (this._parent) ? this._parent.form : new FormGroup({});
  }

  get orderType() {
    if (this.form) {
      if (this.form.controls['orderType'].value) this.getSelectedModuleByOrderType(this.form.controls['orderType'].value);
      return this.form.controls['orderType'];
    };
    return;
  }

  get operationType() {
    if (this.form) return this.form.controls['operationType'];
    return;
  }

  get origin() {
    if (this.form) return this.form.controls['origin'];
    return;
  }

  get otherComments() {
    if (this.form) return this.form.controls['otherComments'];
    return;
  }

  get orderStatus() {
    if (this.form) return this.form.controls['orderStatus'];
    return;
  }

  get priority() {
    if (this.form) return this.form.controls['priority'];
    return;
  }

  get selectModule() {
    if (this.orderTypeForm) return this.orderTypeForm.controls['module'].value;
    return;
  }

  set parent(value: AppointmentFormComponent) {
    this._parent = value;
    if (value) {
      this._parent.createFormValue = () => {
        if (this.form) {
          const appointment = AppointmentHelper.buildFromFormGroup(this.form);
          const dto = new CvisAppointmentDTO(appointment);
          if (this.orderType && this.orderType.value === 'CrmOperation') {
            if (this.operationType) dto.operationType = this.operationType.value;
            if (this.deviceTypeComp) {
              const deviceTypeValue = this.deviceTypeComp.valueForm;
              dto.cameralType = deviceTypeValue.cameralType;
              dto.deviceType = deviceTypeValue.deviceType;
            }
          }
          if (this.origin) dto.patientOrigin = this.origin.value;
          if (this.priority) dto.orderPriority = this.priority.value;
          const orderValue = this.form.controls['order'].value; //?
          if (this.orderType && (!orderValue || !orderValue.length)) {
            const order = new Order;
            order.dtype = this.orderType.value;
            dto.order = [order]
          }
          if (dto.order && dto.order[0]) {
            dto.order[0].otherComments = this.otherComments?.value ?? '';
            dto.order[0].orderStatus = this.orderStatus?.value ?? OrderStatus.NEW;
            dto.order[0].priority = this.priority?.value ?? null;
          }
          return dto;
        }
        return;
      }
      this._parent.reset = (fields?: any) => {
        if (this.form) {
          this.form.reset(fields);
          this.orderTypeForm.reset();
          if (fields && fields['orderType'] && this.orderType) {
            const value = this.orderType.value;
            if (value === 'CrmOperation') {
              this.operationClicked();
            }
          }
        }
      }

      this._parent.fillForm = (appointment: Appointment) => {
        if (this.form) {
          AppointmentHelper.buildFormGroupFromAppointment(appointment, this.form);
          this.activeId = this.detectTab(appointment);
          const playApp = new CrmAppointment(appointment);
          this.appointment = playApp;
          if (this.origin) this.origin.setValue(playApp.getOrigin());
          if (this.priority) this.priority.setValue(playApp.getPriority());
          if (this.otherComments) this.otherComments.setValue(playApp.getOtherComments() ?? '');
          if (this.orderStatus) this.orderStatus.setValue(playApp.getOrderStatus() ?? OrderStatus.NEW);
          if (this.orderType) this.orderType.setValue(playApp.getOrderType());
          if (playApp.getOrderType() === 'CrmOperation' && this.operationType) this.operationType.setValue(playApp.operationType());
        }
      }
    }
  }
}
