import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { DeleteDialogComponent } from '@madrija/angular-base/layout';
import { BasetableComponent, Column, ColumnType } from '@madrija/angular-base/tables';
import { ToastService } from '@madrija/angular-base/toast';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-integration-aux',
  templateUrl: './integration-aux.component.html',
  styleUrls: ['./integration-aux.component.scss'],
  providers: []
})
export class IntegrationAuxComponent{

  public chpaceMsg: string = '';
  public modalRef: NgbModalRef | null = null;
  public cardModalRef: NgbModalRef | null = null;
  public episodes: any[] = [];
  public columns: Column[] = [];

  @ViewChild('sureSendSMS', {static: true}) sureSendSMS: DeleteDialogComponent | null = null;
  @ViewChild('toastTemplate', {static: true}) toastTemplate: TemplateRef<any> | null = null;
  @ViewChild('chpaceMsgDlg', {static: true}) chpaceMsgDlg: TemplateRef<any> | null = null;
  @ViewChild('notEpisodeID', {static: true}) notEpisodeID: TemplateRef<any> | null = null;
  @ViewChild('table', {static: false}) table : BasetableComponent | null = null;
  @ViewChild('printCardTemplate', {static: true}) printCardTemplate: TemplateRef<any> | null = null;

  @Output() callFunction: EventEmitter<{fn: string, params?: any[]}> = new EventEmitter();

  constructor(private modalService: NgbModal, private toastr: ToastService, private translateService: TranslateService) {
    this.createColumns()
  }

  toastClick(event: any) {
    event.stopPropagation();
    this.modalService.open(this.chpaceMsgDlg,{scrollable: true})
  }

  showModalTemplate(chpaceMsg: string, title: string) {
    this.chpaceMsg = chpaceMsg;
    this.toastr.show(chpaceMsg, title, {autohide: false, classname : 'toast-danger long-text-toast', icon :'fa fa-exclamation-circle', template: this.toastTemplate})
  }

  notEpisodeIDModal(data: any) {
    let arrayData = JSON.parse(data);
    const columnsData: any[] = [];
    arrayData.forEach((value : any) => {
      columnsData.push(value.textData);
    });
    var changeID = columnsData.map( item => { 
      var temporal = item.id;
      delete item.id;
      item.pid = temporal;
      return item; 
    });
    this.modalRef = this.modalService.open(this.notEpisodeID);
    this.episodes = changeID;
  }

  selectEpisode() {
    const episode = this.table?.selection;
    const placerOrder = episode.idPrestacion;
    this.callFunction.emit({fn: 'savePlaceOrder', params: [placerOrder]});
  }

  showConfirmationPrintModal() {
    this.cardModalRef = this.modalService.open(this.printCardTemplate);
  }

  printCard() {
    this.callFunction.emit({fn: 'printDeviceCard'});
  }

  private createColumns() {
    this.translateService.get(['TIMELINE.Date', 'TIMELINE.Id', 'TIMELINE.Service', 'TIMELINE.Description']).subscribe(translations => {
      this.columns = [
        { field: 'startDate', header: translations['TIMELINE.Date'], type: ColumnType.STRING, showfilter: false },
        { field: 'idPrestacion', header: translations['TIMELINE.Id'], type: ColumnType.STRING, showfilter: false},
        { field: 'servicioAdmision', header: translations['TIMELINE.Service'], type: ColumnType.STRING, showfilter: false},
        { field: 'descripcionServicio', header: translations['TIMELINE.Description'], type: ColumnType.STRING, showfilter: false}
      ];
    });
  }

}