import { KeyValue } from '@madrija/angular-base/common';
import { Appointment } from '@madrija/medical-ui/common';
import { KeyValueHelper } from '@madrija/base-ui/common';
import { Operation, OperationType, Device, DeviceCameralType, ImportType, FollowUp, OperationHelper, DeviceType } from '@madrija/crm-ui/common';
import { Procedure } from '@madrija/electro-ui/common';
import { Procedure as SihProcedure } from '@madrija/sih-ui/common';
import { CimEcoCardio, CimTAC } from '@madrija/cardio-image-ui/common';
import { EcgTest } from '@madrija/ecg-ui/common';

export class CvisAppointment extends Appointment {

  override order: Array<FollowUp | Operation | Procedure | SihProcedure | CimEcoCardio | CimTAC | EcgTest> | null = null;

  public constructor(appointment?: Appointment | undefined) {
    super(appointment);
    if (appointment) this.order = appointment.order as Array<FollowUp | Operation | Procedure | SihProcedure | CimEcoCardio | CimTAC | EcgTest> | null;

  }

  public isImported(): boolean {
    let result = false;
    const order: any = this.getOrder();
    if (order) {
      result = order.autoFill != null;
    }
    return result;
  }
  public getImportedType(): string {
    let result = null;
    const order: any = this.getOrder();
    if (order) {
      result = order.importType;
    }
    return result as string;
  }
  public getOrderType(): string {
    let result = '';
    const order = this.getOrder();
    if (order && order.dtype) {
      result = order.dtype;
    }
    return result;
  }
  public operationType(): OperationType[] {
    let result: Array<OperationType> = new Array;
    const order = this.getOrder();
    if (order && this.getOrderType() === 'CrmOperation') {
      const operation = OperationHelper.buildFromJSON(order);
      if (operation.operationType) result = operation.operationType;
    }
    return result;
  }
  get deviceType(): DeviceType | null {
    let result = null;
    const order = this.getOrder();
    if (order && this.getOrderType() === 'CrmOperation') {
      const operation = OperationHelper.buildFromJSON(order);
      result = (operation.deviceType) ? operation.deviceType : null;
    }
    return result;
  }
  public cameralType(): string {
    let result = '';
    const order = this.getOrder();
    if (order && this.getOrderType() === 'CrmOperation') {
      const operation = OperationHelper.buildFromJSON(order);
      result = (operation.cameralType) ? operation.cameralType.toString() : '';
    }
    return result;
  }
  public getOrigin(): KeyValue | null {
    let result = null;
    const order = this.getOrder();
    if (order && order.origin) {
      result = order.origin;
      if (!(result instanceof KeyValue)) {
        result = KeyValueHelper.buildFromJSON(result)
      }
    }
    return result;
  }
  public modelType(string: string): string {
    let result = '';
    const order: any = this.getOrder();
    if (order && order.crmDeviceStatus && order.crmDeviceStatus.crmDevice
      && order.crmDeviceStatus.crmDevice.crmModel && order.crmDeviceStatus.crmDevice.crmModel.name) {
      const keyvalue = KeyValueHelper.buildFromJSON(order.crmDeviceStatus.crmDevice.crmModel.name)
      const value = keyvalue.valueByLocale(string);
      result = value ? value : '';
    }
    return result
  }
  public deviceModelType(): string {
    let result = '';
    const order: any = this.getOrder();
    if (order && order.crmDeviceStatus && order.crmDeviceStatus.crmDevice
      && order.crmDeviceStatus.crmDevice.crmModel && order.crmDeviceStatus.crmDevice.crmModel.type) {
      result = order.crmDeviceStatus.crmDevice.crmModel.type;

    }
    return result
  }

  public serialNumber(): string {
    let result = '';
    const order: any = this.getOrder();
    if (order && order.crmDeviceStatus && order.crmDeviceStatus.crmDevice
      && order.crmDeviceStatus.crmDevice.serialNumber) {
      result = order.crmDeviceStatus.crmDevice.serialNumber;

    }
    return result
  }

  public manufacturer(): string {
    const order: FollowUp | Operation | Procedure | SihProcedure | CimEcoCardio | CimTAC | EcgTest | null = this.getOrder() as FollowUp | Operation | Procedure | SihProcedure | CimEcoCardio | CimTAC | EcgTest | null;
    return order && (order instanceof FollowUp || order instanceof Operation) ? order.crmDeviceStatus?.crmDevice?.crmModel?.manufacturer?.name ?? '' : '';
  }

  public device(): Device {
    let result = new Device;
    const order: any = this.getOrder();
    if (order && order.crmDeviceStatus && order.crmDeviceStatus.crmDevice) {
      const device = order.crmDeviceStatus.crmDevice;
      result = device;

    }
    return result;
  }

  importIcon(): string {
    let icon = 'fa fa-file-import';
    const type = this.getImportedType();
    if (type) {
      switch (type) {
        case ImportType.INCLINIC_EBOX:
          icon = 'ebox';
          break;
        case ImportType.REMOTE:
          icon = 'fas fa-globe';
          break;
        default:
          icon = 'fa fa-file-import';
          break;
      }
    }
    return icon;
  }
  getModuleSrc(module?: string) {
    let src = '';
    if (this.orderModule() !== '' || module !== undefined) {
      src = `assets/images/modules/${module ?? this.orderModule()}.svg`;
    }
    return src;
  }
  getOrderTypeSrc() {
    let src = '';
    const type = this.getOrderType();
    if (type) {
      switch (type) {
        case 'CimEcoCardio':
          src = 'assets/images/order-types/eco.svg';
          break;
        case 'CimTAC':
          src = 'assets/images/order-types/tac.svg';
          break;
      }
    }
    return src;
  }
  orderTypeIcon(): string {
    let icon = '';
    const type = this.getOrderType();
    if (type) {
      switch (type) {
        case 'CrmOperation': case 'EpProcedure': case 'SihProcedure':
          icon = 'fa fa-radiation';
          break;
        case 'CrmFollowUp': case 'EcgTest': case 'SihFollowUp':
          icon = 'fa fa-stethoscope';
          break;
        case 'FA_DEBUT':
          icon = 'fa-solid fa-wave-square';
          break;
        case 'SURVEY':
          icon = 'fa-solid fa-newspaper';
          break;
        case 'LOST_TRANSMISSION':
          icon = 'fa-solid fa-comment-slash';
          break;
        case 'DIAGNOSTIC_PROCEDURE':
          icon = 'fa-solid fa-rectangle-list';
          break;
        case 'IOT_MEASUREMENT':
          icon = 'fa-solid fa-signal';
          break;
        default:
          break;
      }
    }
    return icon;
  }
  orderModule() {
    let module = '';
    const type = this.getOrderType();
    if (type) {
      switch (type) {
        case 'CrmOperation': case 'CrmFollowUp':
          module = 'crm';
          break;
        case 'EpProcedure':
          module = 'ep';
          break;
        case 'SihFollowUp': case 'SihProcedure':
          module = 'sih';
          break;
        case 'CimEcoCardio': case 'CimTAC':
          module = 'cim';
          break;
        case 'EcgTest':
          module = 'ecg';
          break;
        default:
          break;
      }
    }
    return module;
  }
  operationTypeIcon(): string {
    let icon = '';
    const types = this.operationType();
    for (const iterator of types) {
      if (icon !== '') icon = icon.concat('/');
      icon = icon.concat(this.getOperationTypeIconByType(iterator));
    }
    return icon;
  }

  getOperationTypeIconByType(type: OperationType) {
    let icon = '';
    switch (type) {
      case OperationType.FIRSTIMPLANT:
        icon = 'F';
        break;
      case OperationType.REPLACEMENT:
        icon = 'RP';
        break;
      case OperationType.UPGRADE:
        icon = 'U';
        break;
      case OperationType.DOWNGRADE:
        icon = 'D';
        break;
      case OperationType.REIMPLANT:
        icon = 'RI';
        break;
      case OperationType.EXPLANT:
        icon = 'E';
        break;
      case OperationType.EXTRACTION:
        icon = 'X';
        break;
      case OperationType.REVIEW:
        icon = 'RE';
        break;
      case OperationType.RELOCATION:
        icon = 'RL';
        break;
      default:
        break;
    }
    return icon;
  }

  cameralTypeIcon(): string {
    let icon = '';
    const type = this.cameralType();
    if (type) {
      switch (type) {
        case DeviceCameralType.MONOCAMERAL:
          icon = '1';
          break;
        case DeviceCameralType.BICAMERAL:
          icon = '2';
          break;
        case DeviceCameralType.TRICAMERAL:
          icon = '3';
          break;
        default:
          break;
      }
    }
    return icon;
  }

  checkClinicalEvent() {
    let result = true;
    const type = this.getOrderType();
    if (type) {
      switch (type) {
        case 'SURVEY': case 'LOST_TRANSMISSION': case 'DIAGNOSTIC_PROCEDURE': case 'IOT_MEASUREMENT': case 'FA_DEBUT':
          result = false;
          break;
        default:
          break;
      }
    }
    return result;
  }


  static moduleHasType(module: string, type: string) {
    let result = '';
    if (type) {
      switch (module.toLowerCase()) {
        case 'crm':
          result = type === 'FollowUp' ? 'CrmFollowUp' : type === 'Operation' ? 'CrmOperation' : '';
          break;
        case 'sih':
          result = type === 'FollowUp' ? 'SihFollowUp' : type === 'Procedure' ? 'SihProcedure' : '';
          break;
        case 'ep':
          result = type === 'Procedure' ? 'EpProcedure' : '';
          break;
        case 'cim':
          result = type === 'TAC' ? 'CimTAC' : type === 'EcoCardio' ? 'CimEcoCardio' : '';
          break;
        case 'uc':
          result = 'uc';
          break;
        case 'rhc':
          result = 'rhc';
          break;
        case 'insufi':
          result = 'insufi';
          break;
        case 'ecg':
          result = type === 'Test' ? 'EcgTest' : '';
          break;
        default:
          break;
      }
    }
    return result;
  }

  static override dtoMapper(): { [key: string]: { label: string; initialValue?: any; }; } {
    return Object.assign(super.dtoMapper(),
      {
        orderPid: { label: 'order.0.pid', initialValue: [{ origin: {}, priority: {}, crmDeviceStatus: { crmDevice: { crmModel: { manufacturer: {}, name: {} } } } }] },

        autofill: { label: 'order.0.autoFill' },
        importType: { label: 'order.0.importType' },

        operationType: { label: 'order.0.operationType' },
        operationDeviceType: { label: 'order.0.deviceType' },
        cameralType: { label: 'order.0.cameralType' },

        originPid: { label: 'order.0.origin.pid' },
        originValue: { label: 'order.0.origin.value' },
        originTranslations: { label: 'order.0.origin.translationValues' },

        priorityPid: { label: 'order.0.priority.pid' },
        priorityValue: { label: 'order.0.priority.value' },
        priorityTranslations: { label: 'order.0.priority.translationValues' },

        modelNamePid: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.name.pid' },
        modelNameValue: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.name.value' },
        modelNameTranslations: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.name.translationValues' },

        modelType: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.type' },
        serialNumber: { label: 'order.0.crmDeviceStatus.crmDevice.serialNumber' },
        vendorPid: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.manufacturer.pid' },
        vendorName: { label: 'order.0.crmDeviceStatus.crmDevice.crmModel.manufacturer.name' },

      });
  }
}

