<mab-form-control [level]="1" [formGroup]="form" [formId]="'CRM.appointments.appointments_form.origin'"
  [labelValue]="'PACIENT.Origin' | rootTranslate" [labelFor]="'origin'" [columns]="'col-lg-6 col-sm-12'"
  [clazz]="'mb-4 pull-left'" [validationRequiredError]="'COMMON.validationRequiredError' | rootTranslate"
  [validationRegexError]="'COMMON.validationRegexError' | rootTranslate">
  <mab-autocompletekv [loc_add_title]="'KEY-VALUE.add' | rootTranslate"
    [translation_fields]="'KEY-VALUE.translation_fields' | rootTranslate"
    [required_literal]="'COMMON.Required'|translate" [defaultValue]="'KEY-VALUE.defaultValue' | rootTranslate"
    [required_fields]="'KEY-VALUE.required_fields' | rootTranslate" [loc_save]="'COMMON.Save'|translate"
    [loc_cancel]="'COMMON.Cancel'|translate" [loc_continue]="'COMMON.Continue'|translate"
    [loc_delete_title]="'COMMON.Delete'|translate"
    [loc_delete_msg]="'COMMON.YouSure' | rootTranslate:{'action':'COMMON.Delete' | rootTranslate}" [canAdd]="true"
    [multipleTag]="false" key="med_origin" formControlName="origin">
  </mab-autocompletekv>
</mab-form-control>

<mab-form-control [level]="1" [formGroup]="form" [formId]="'CRM.appointments.appointments_form.priority'"
  [labelValue]="'PACIENT.Priority' | translate" [labelFor]="'priority'" [columns]="'col-lg-6 col-sm-12'"
  [clazz]="'mb-4 pull-left'" [validationRequiredError]="'COMMON.validationRequiredError' | translate"
  [validationRegexError]="'COMMON.validationRegexError' | translate">
  <mab-autocompletekv [loc_add_title]="'KEY-VALUE.add' | translate"
    [translation_fields]="'KEY-VALUE.translation_fields' | translate" [required_literal]="'COMMON.Required'|translate"
    [defaultValue]="'KEY-VALUE.defaultValue' | translate" [required_fields]="'KEY-VALUE.required_fields' | translate"
    [loc_save]="'COMMON.Save'|translate" [loc_cancel]="'COMMON.Cancel'|translate"
    [loc_continue]="'COMMON.Continue'|translate" [loc_delete_title]="'COMMON.Delete'|translate"
    [loc_delete_msg]="'COMMON.YouSure' | translate:{'action':'COMMON.Delete' | translate}" [canAdd]="true"
    [multipleTag]="false" key="med_priority" formControlName="priority">
  </mab-autocompletekv>
</mab-form-control>

<ng-template #orderTypeTemplate>
  <div class="form-group">
    <mab-form-control [level]="1" [formGroup]="orderTypeForm" [isRequired]="true" *ngIf="show('module')"
      [formId]="'CVIS.appointments.appointments_form.module'" [labelValue]="'Appointment.module' | rootTranslate"
      [columns]="''" [clazz]="'row mb-4 pull-left'"
      [validationRequiredError]="'COMMON.validationRequiredError' | rootTranslate"
      [validationRegexError]="'COMMON.validationRegexError' | rootTranslate">
      <ng-container *ngFor="let module of cvisModules">
        <div *ngIf="hasModule(module.toLowerCase())" class="col-6">
          <div class="form-check ps-0 py-1">
            <input type="radio" class="form-check-input-custom" [id]="module" [value]="module"
              (change)="operationClicked()" formControlName="module" name="module">
            <label class="form-check-label py-3" [for]="module">{{'MODULE.'+ module | rootTranslate}}
              <img [src]="'assets/images/modules/' + module.toLowerCase() + '.svg'" width="20" height="20"></label>
          </div>
        </div>
      </ng-container>
    </mab-form-control>
    <div *ngIf="show('type')" class="type-view">
      <div class="pb-3">
        <i class="fas fa-arrow-left pe-3" *ngIf="!hasOrderCreated()" (click)="backToSelectModule()"></i>
        <span>{{('Appointment.module' | rootTranslate )+ ':'}}<span class="module">{{('MODULE.' +
            capitalizeFirstLetter(selectModule) |
            rootTranslate)}}</span></span>
      </div>
      <mab-form-control [level]="1" [formGroup]="form" [isRequired]="true"
        [formId]="'CVIS.appointments.appointments_form.type'" [labelValue]="'Appointment.orderType' | rootTranslate"
        [columns]="''" [clazz]="'row mb-4 pull-left not-show-required'"
        [validationRequiredError]="'COMMON.validationRequiredError' | rootTranslate"
        [validationRegexError]="'COMMON.validationRegexError' | rootTranslate">
        <ng-container *ngFor="let type of orderTypes">
          <div *ngIf="moduleHasType(type) !== ''" class="col-6">
            <div class="form-check ps-0 py-1">
              <input type="radio" class="form-check-input-custom" [id]="moduleHasType(type)"
                [value]="moduleHasType(type)" formControlName="orderType" name="orderType">
              <label class="form-check-label py-3" [for]="moduleHasType(type)">{{'ORDER.'+ type | rootTranslate}}
                <i *ngIf="selectType(moduleHasType(type))" class="fas fa-check-circle"></i>
              </label>
            </div>
          </div>
        </ng-container>
      </mab-form-control>
    </div>
  </div>
</ng-template>

<mab-form-control [level]="1" *ngIf="operationType && orderType?.value === 'CrmOperation'" [formGroup]="form"
  [formId]="'CRM.orderForm.operation.operationType'" [labelValue]="'OPERATION.type' | rootTranslate"
  [labelFor]="'operationType'" [columns]="'col-lg-6 col-sm-12'" [clazz]="'mb-4'"
  [validationRequiredError]="'COMMON.validationRequiredError' | rootTranslate"
  [validationRegexError]="'COMMON.validationRegexError' | rootTranslate">
  <ng-select class="custom" [multiple]="true" [items]="operationTypes" bindLabel="label" bindValue="value"
    formControlName="operationType"></ng-select>
</mab-form-control>
<div *ngIf="orderType?.value === 'CrmOperation'" class="row col-12 mx-0">
  <app-device-type #deviceTypeComp [formId]="'CRM.orderForm.operation.'" [value]="appointment"
    [isTwoColumns]="true"></app-device-type>
</div>