import { PatientHistoryOrder } from '@madrija/medical-ui/common';
import { CrmHistoryDTO } from '@madrija/crm-ui/common';
import { Order } from '@madrija/medical-ui/common';

export class CvisOrderHistoryDTO extends PatientHistoryOrder {
  public crmHistoryDTO: CrmHistoryDTO | null = null
}

export class OrderDTO extends Order {
  public autoFill: Date | null = null;
  public importType: string | null = null;
  public operationType: any[] | null = null;
  public operationDeviceType: string | null = null;
  public cameralType: string | null = null;
}
